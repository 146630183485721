/* ----------------------------------------------------------------------------
 * FastFundDropDown Styles
 * ----------------------------------------------------------------------------
 */

select.ff-dropdown {
  display:                none;
}

.ff-dropdown:hover {
  cursor:                 pointer;
}

.ff-dropdown > i {
  margin-left:           .5em !important;
}
