/* ----------------------------------------------------------------------------
 * Icon Styles
 * ----------------------------------------------------------------------------
 */

i.fal, i.far, i.fas {
  margin:               0 .25rem;
}

i.disabled {
  color:                var(--gray60) !important;
  cursor:               not-allowed !important;
  pointer-events:       none;
}

i:not(.icon-left):hover {
  cursor:               pointer;
}

i.delete-icon {
  font-size:            1.5em;
  color:                var(--red);
}

i.icon-left {
  color:                var(--gray60);
  position:             absolute;
  left:                 .25rem;
  top:                  50%;
  transform:            translateY(-50%);
}

input.icon-left:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]) {
  padding-left:         30px !important;
}

