/* ----------------------------------------------------------------------------
 * Dropdowns
 * ----------------------------------------------------------------------------
 */
div.ff-menu {
  background-color:   white;
  border:             1px solid var(--gray60);
  box-shadow:         5px 10px 10px -4px var(--gray);
  color:              var(--dark);
  display:            none;
  position:           absolute;
  width:              max-content;
}

ul.ff-menu {
  list-style:         none;
  margin:             0;
  max-width:          50vw;
  overflow-y:         auto;
  overflow-x:         auto;
  padding:            .5em;

  /* prevent selection highlighting inside the menu */
  -moz-user-select:       -moz-none;
  -khtml-user-select:     none;
  -webkit-user-select:    none;
}

ul.ff-menu label {
  display:            flex;
  align-items:        center;
  font-weight:        var(--regular);
}

ul.ff-menu label.group {
  font-weight:        var(--medium);
  text-decoration:    underline;
}

ul.ff-menu label:hover {
  color:              black;
}

ul.ff-menu li {
  cursor:             pointer;
  padding:            .15em .5em;
  white-space:        nowrap;
}

ul.ff-menu li.border-top {
  border-top:         1px solid var(--gray60);
  margin:             .5em 0 0 0;
  width:              100%;
}

ul.ff-menu li.border-bottom {
  border-bottom:      1px solid var(--gray60);
  margin:             0 0 .5em 0;
  width:              100%;
}

ul.ff-menu li.group {
  display:                grid;
  align-items:            center;
  grid-template-columns:  auto 1fr;
  grid-column-gap:        .5em;
}

ul.ff-menu li:hover:not(.disabled) {
  background-color:   var(--secondaryBlue);
}

ul.ff-menu li.sidemenu-item-option:hover:not(.disabled) {
  color:              var(--slate);
}

ul.ff-menu li.disabled {
  color:              var(--dark60);
  cursor:             not-allowed;
  font-style:         italic;
  pointer-events:     none;
}

ul.ff-menu li.inactive,
ul.ff-menu li[data-inactive] {
  /* same appearance as disabled but selectable/clickable */
  color:              var(--dark60);
  font-style:         italic;
}

ul.ff-menu li.separator {
  border-top:         1px solid var(--dark30);
  height:             0;
  margin:             3px 0;
  padding:            0;
  overflow:           hidden;
}

ul.ff-menu li.menu-header {
  background-color:   var(--gray60);
  color:              var(--dark);
  font-style:         normal;
  font-weight:        var(--medium);
  padding:            .25em;
}

ul.ff-menu li.selected {
  background-color:   var(--gray30);
  color:              black;
}

ul.ff-menu input[type="checkbox"] {
  margin:             0 .5em 0 0;
}

ul.ff-menu input[type="checkbox"]:focus {
  outline:            none;
}
