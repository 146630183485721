@use "breakpoints";

/* ----------------------------------------------------------------------------
 * CSS for the top navigation headers
 * ----------------------------------------------------------------------------
 */
.ff-header1 a {
  color:                white;
  font-weight:          var(--medium);
  margin:               0 1rem 0 0;
  white-space:          nowrap;
}

.ff-header2 a {
  color:                var(--gray);
  font-weight:          var(--medium);
  margin:               0 1rem 0 0;
  white-space:          nowrap;
}

.ff-header2 a:hover {
  color:                var(--primaryBlue);
}

.ff-header1 a:hover,
.header-name:hover,
.header-links .selected {
  color:                var(--yellow);
  text-decoration:      none;
}

.h1-links {
  display:              grid;
  grid-auto-flow:       column;
}

.ff-header2 a.selected {
  color:                var(--gray);
  border-bottom:        5px solid var(--primaryBlue);
}

.company-name {
  grid-area:            company;
}

.feature-name {
  color:                var(--gray);
  font-weight:          var(--medium);
  padding-right:        2rem;
}

.feature-select:hover {
  color:                var(--primaryBlue) !important;
}

.header-name {
  color:                white;
  font-weight:          var(--medium);
}

.user-name {
  padding-left:         1rem;
}

div.header-logo {
 grid-area:             logo;
}

a.header-logo img {
  height:               auto;
  width:                32px;
}

a.header-logo img:hover {
  filter: brightness(110%);
}

/* ----------------------------------------------------------------------------
 * CSS for main page content header
 * ----------------------------------------------------------------------------
 */

.content-header-container {
  margin-bottom:          1.25rem;
}

.content-header {
  /*
  @extend .grid;

  grid-template-columns:  auto auto;
  grid-column-gap:        1vw;
  */
  display:                flex;
  align-items:            center;
  flex-wrap:              wrap;
  column-gap:             3vw;
  row-gap:                1em;
}

/* single column on small screens */
@media only screen and (max-width: breakpoints.$bp-lg) {
  .content-header         { grid-template-columns:  auto; }
}

.content-header-buttons {}
.content-header-buttons .ff-button {
  margin:                 1px 2px 1px 2px !important;
}

.content-header-buttons label {
  margin-left:            .5em;
}

.content-header-links {
  color:                  var(--gray);
  font-weight:            var(--medium);
  line-height:            2em;
}

.content-header-links a {
  white-space:            nowrap;
}

.content-header-links a.selected {
  color:                  var(--gray);
  border-bottom:          5px solid var(--primaryBlue);
}

.content-header-links a.selected i {
  color:                  var(--gray);
}

.content-header-links i {
  color:                  var(--primaryBlue);
  font-size:              12px;
}

.content-header-links a:hover,
.content-header-links a:hover i {
  color:                  var(--gray);
}

.content-header-extra {
  background-color:       var(--gray30);
  border-radius:          4px;
  color:                  black;
  margin-top:             .5rem;
  padding:                .5rem;
}

.content-header-filters {
  margin-top:             1rem;
  padding:                0 .5rem;
}

.content-pager {
  display:                grid;
  align-items:            center;
  grid-auto-flow:         column;
  grid-column-gap:        1.5rem;
  justify-content:        start;
  padding:                0 .5rem .25rem .5rem;
}

.content-pager .current {
  background-color:       var(--gray15);
  border:                 1px solid var(--gray30);
  padding:                0 1px;
}

.content-pager .page {
  padding:                0 2px;
}

.content-pager input[type=checkbox] {
  margin:                 2px auto;
}

div.pager-action {
  display:                inline-block;
  color:                  var(--primaryBlue);
}
