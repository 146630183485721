/* ----------------------------------------------------------------------------
 * FastFundSearch Styles
 * ----------------------------------------------------------------------------
 */

div.ff-search-container {
  align-items:            center !important;
  background-color:       var(--gray30);
  border-bottom:          1px solid var(--gray60);
  padding:                .25em;
}

input.ff-search-input {
  background-color:       white;
  border:                 1px solid var(--gray60);
  border-radius:          4px;
  font-weight:            var(--regular);
  height:                 2em;
  padding:                4px 8px;
  min-width:              14em;
  width:                  100%;
}

input.ff-search-input:focus {
  //box-shadow:             0 0 8px 0 var(--primaryBlue60) !important;
  outline:                none !important;
}

