@use "fastfund";

/* ----------------------------------------------------------------------------
 * Modal Styles
 * ----------------------------------------------------------------------------
 */
.ff-modal-overlay {
  animation:              fadeIn 0.2s;
  background-color:       rgba(0,0,0,0.5);
  display:                grid;
  align-items:            center;
  width:                  100%;
  height:                 100%;
  overflow-y:             hidden;
  position:               fixed;
}

.ff-modal-overlay-private {
  background-color:       black;
}

.ff-modal {
  animation:              fadeIn 0.3s;
  background-color:       var(--gray30);
  border:                 1px solid var(--gray60);
  box-shadow:             5px 10px 10px var(--dark);
  display:                grid;
  height:                 auto;
  justify-self:           center;
  max-width:              95vw;
  min-width:              35vw;
  width:                  65vw;
  position:               absolute;
}

@media only screen and (min-height: 960px) {
  .ff-modal {
    width:                75vw;
  }
}

.ff-modal-title {
  display:                grid;
  align-items:            center;
  border-bottom:          1px solid var(--gray60);
  grid-template-columns:  1fr 1fr auto;
  padding:                .75rem .5rem;
  position:               relative;
}

.ff-modal-title div {
  color:                  var(--dark);
}

.ff-modal-content {
  background-color:       var(--gray15);
  border-bottom:          7px solid var(--gray60);
  padding:                1rem;
  max-height:             85vh;
  overflow-y:             auto;
}

.ff-modal-buttons {
  justify-self:           end;
  margin-right:           1.5rem;
}

.ff-modal-close {
  @extend .h2;
  position:               absolute;
  top:                    4px;
  right:                  4px;
}

.ff-modal-close:hover {
  color:                  var(--dark60);
  cursor:                 pointer;
}

.ff-modal-spinner {
  @extend .h5;
  top:                    8px;
  right:                  5px;
}

.ff-modal-handle {
  cursor:                 move;
}
