@use "breakpoints";

/* ----------------------------------------------------------------------------
 * Main Layout
 * ----------------------------------------------------------------------------
 */

/* mobile first styles */
.ff-body {
  display:                  grid;
  grid-template-columns:    1fr;
  grid-template-rows:       auto 1fr;  /* header, content (footer is within content) */
  grid-gap:                 0;
}

.ff-header-container {
  display:                  grid;
  grid-column:              1;
  grid-row:                 1;
}

.ff-header1 {
  align-items:              center;
  background-color:         var(--primaryBlue);
  color:                    white;
  display:                  grid;
  grid-row-gap:             .5rem;
  justify-content:          left;
  padding:                  .5rem 1rem;
  grid-template-areas:
    "logo company"
    "logo links";
}

.ff-header2 {
  align-items:              center;
  background-color:         var(--secondaryBlue);
  border-bottom:            1px solid var(--primaryBlue30);
  display:                  grid;
  grid-row-gap:             .5rem;
  padding:                  .75rem 1rem;
}

.ff-content-container {
  background-color:         white;
  grid-column:              1;
  grid-row:                 2;
  overflow-y:               auto;
  padding:                  1rem 1.5rem 0 1.5rem;
}

.ff-content {}
.ff-content-padding {
  height:                   15px;
}

.ff-footer  {
  position:                 fixed;
  left:                     0;
  bottom:                   0;
  width:                    100%;
  height:                   1px;
}

.ff-sidemenu {
  background-color:         var(--slate);
  color:                    white;
  display:                  none;
  padding-bottom:           1rem;
  overflow-y:               auto;
  overflow-x:               hidden;
  white-space:              nowrap;
}

.burger-link {
  position:                 absolute;
  top:                      9px;
  right:                    3px;
  z-index:                  10;
}

.burger-link i {
  font-size:                1.3rem;
}
.burger-link span {
  color:                    white;
}

.burger-close-link {
  color:                    white;
  font-size:                1.5rem;
  position:                 absolute;
  top:                      .25rem;
  left:                     .25rem;
}

.burger-close-link:hover {
  color:                    var(--dark15);
}

/* any device larger than --bp-lg */
@media only screen and (min-width: breakpoints.$bp-lg) {
  .ff-body {
    grid-template-columns:  auto 1fr;  /* sidebar and main content */
  }

  .burger-link,
  .burger-close-link {
    display:                none;
  }

  .ff-header-container,
  .ff-content-container {
    grid-column:            2;
  }

  .ff-header1 {
    grid-template-areas:    "logo company pad links";
    grid-template-columns:  4rem auto 1fr auto;
  }

  .ff-header2 {
    grid-template-columns:  4rem auto 1fr;
  }

  .ff-sidemenu {
    display:                block;
    grid-column:            1;
    grid-row:               1/-1;
  }
}

/* fix main content to 1800px for any device larger than 2000 */
@media only screen and (min-width: breakpoints.$bp-ws) {
  .ff-body {
    grid-template-columns:  auto auto 1800px auto;
  }

  .ff-header-container,
  .ff-content-container {
    grid-column:            3;
  }

 .ff-sidemenu {
    grid-column:            2;
  }
}

/* ----------------------------------------------------------------------------
 * Login Layout
 * ----------------------------------------------------------------------------
 */
html.login {
  background-color:         white;
}

.login-body {
  display:                  grid;
  grid-gap:                 0;
  /* mobile first */
  grid-template-columns:    1fr;
  grid-template-rows:       repeat(6, auto);
  grid-template-areas:
    "h"
    "b"
    "l"
    "a"
    "s"
    "f";
}

@media only screen and (min-width: breakpoints.$bp-md) {
  .login-body {
    grid-template-rows:     auto auto auto 1fr auto;
    grid-template-columns:  1fr minmax(auto, breakpoints.$bp-sm) minmax(auto, breakpoints.$bp-sm) 1fr;
    grid-template-areas:
      "h h h h"
      "b b b b"
      ". l a ."
      "s s s s"
      "f f f f";
  }
}

.login-header {
  background-color:         var(--primaryBlue);
  grid-area:                h;
  padding:                  .3rem .5rem;
}

.login-footer {
  background-color:         white;
  grid-area:                f;
  text-align:               center;
  padding:                  .5rem;
}

.login-banner {
  grid-area:                b;
  font-size:                3rem;
  padding:                  3rem;
  text-align:               center;
}

.login-form {
  background-color:         var(--gray15);
  display:                  grid;
  grid-area:                l;
  padding:                  1rem;
}

.login-nav {
  align-self:               end;
  text-align:               center;
}

.login-ad {
  background-color:         var(--secondaryBlue);
  display:                  grid;
  grid-area:                a;
  text-align:               center;
}

.login-ad-image {
  height:                   100%;
  width:                    100%;
  max-height:               325px;
  max-width:                325px;
}

.login-summary {
  background-color:         white;
  grid-area:                s;
  text-align:               center;
  padding:                  2rem;
}
