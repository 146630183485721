/* ----------------------------------------------------------------------------
 * Control Register Styles
 * ----------------------------------------------------------------------------
 */
div.register-head {
  background-color:       var(--gray);
  color:                  white;
  font-weight:            var(--medium);
  padding:                .25rem .5rem;
  white-space:            nowrap;
  width:                  100%;
}

div.register-body {
  background-color:       var(--gray30);
  border:                 1px solid var(--gray60);
  overflow-x:             hidden;
}

div.register-row {
  display:                grid;
  grid-gap:               0;
}

div.register-row1 {
  grid-template-columns:  10% 25% 25% 4% 12% 12% 12%;
}

div.register-row2 {
  grid-template-columns:  10% 25% 25% 40%;
}

div.register-row1-fr {
  grid-template-columns:  10% 20% 25% 9% 12% 12% 12%;
}

div.register-row2-fr {
  grid-template-columns:  10% 20% 25% 45%;
}

div.register-body .register-row div {
  padding:                1px .25rem;
  overflow:               hidden;
  text-overflow:          ellipsis;
  white-space:            nowrap;
}

div.register-body .register-row1 {
  border-top:             1px solid var(--gray60);
  padding-top:            .2rem;
}

div.register-body .register-row2,
div.register-body .register-row2-fr {
  padding-bottom:         .2rem;
}

div.register-body .register-row:nth-child(4n),
div.register-body .register-row:nth-child(4n-1) {
  background-color:       white;
}
