@use "breakpoints";

/* ----------------------------------------------------------------------------
 * Filter Styles
 * ----------------------------------------------------------------------------
 */
div.filter-container {
  display:                flex;
  flex-wrap:              wrap;
}

div.filter-container > div {
  flex:                   1 1 8rem;
  max-width:              100%;
  margin:                 .10rem .25rem;
}

@media only screen and (min-width: breakpoints.$bp-lg) {
  div.filter-container > div {
    flex:                 1 1 12rem;
    max-width:            50%;
  }
}

div.filter-container div > label {
  white-space:            nowrap;
}

div.filter-container input {
  display:                block;
}

div.filter-amount {
  display:                grid;
  grid-gap:               .25em;
  grid-template-columns:  auto 1fr;
}

div.filter-group {
  display:                flex;
}

div.filter-group > div {
  margin:                 0 .25rem;
}

