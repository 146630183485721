/* ----------------------------------------------------------------------------
 * Misc Styles
 * ----------------------------------------------------------------------------
 */
.ff-card {
  background-color:   var(--yellow30);
  border:             1px solid var(--yellow);
  padding:            .5rem .75rem;
}

.ff-info {
  /* used for tooltips and flash messages */
  animation:          fadeIn 0.4s;
  border:             1px solid rgba(0,0,0,.2);
  border-radius:      4px;
  box-shadow:         2px 2px 5px rgba(0,0,0,0.7);
}

.ff-double-select-icons i {
  display:            block;
  font-size:          1.5rem;
  margin:             1rem 0;
}

.ff-double-select-icons i:hover {
  color:              var(--dark60);
}

.box-gray {
  border:             1px solid var(--gray60);
  background-color:   var(--gray30);
  padding:            0.5rem
}

ul.ff-help > li {
  margin:             .25rem 0;
}
