/* ----------------------------------------------------------------------------
 * Forms
 * ----------------------------------------------------------------------------
 */
input::placeholder {
  color:                var(--gray60);
  opacity:              1;
}

.form-input {
  background-color:     white;
  border:               1px solid var(--gray60);
  border-radius:        4px;
  font-weight:          var(--regular);
  padding:              4px 8px;
}

.ff-form div.input,
.ff-form select,
.ff-form input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]),
.ff-form textarea {
  @extend               .form-input;
}

.ff-form div.input,
.ff-form select,
.ff-form input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]) {
  /* force inputs and select to same height */
  height:               2em;
}

.ff-form input[type=number] {
  /* allow space for control */
  padding-right:        10px !important;
}

.ff-form textarea {
  padding:              4px 8px 0 8px;
}

.ff-form select[multiple],
.ff-form select[size] {
  height:               initial !important;
}

.ff-form select:disabled,
.ff-form input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]):disabled,
.ff-form textarea:disabled,
.ff-form select.readonly,
.ff-form input.readonly:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=checkbox]):not([type=radio]),
.ff-form div.readonly,
.ff-form textarea.readonly {
  background-color:     var(--gray30);
  color:                var(--dark);
}

.ff-form select,
.ff-form textarea,
.ff-form input[type=text],
.ff-form input[type=email],
.ff-form input[type=number],
.ff-form input[type=password],
.ff-form input[type=file] {
  max-width:            100%;
  width:                100%;
}

.ff-form select:not([multiple]):not([size]),
.ff-form input.ff-multiselect {
  /*
     SVG src encoding: CGI::escape(foo).gsub('+', '%20'), decoding: CGI::unescape(foo)
     NOTE: src must contain xmlns="http://www.w3.org/2000/svg" to display properly
  */
  background-image:     url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M7.41%2C8.58L12%2C13.17L16.59%2C8.58L18%2C10L12%2C16L6%2C10L7.41%2C8.58Z%22%20%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat:    no-repeat !important;
  background-position:  center right !important;
  padding-right:        1.5em !important;
}

.ff-form input:not([type=button]):not([type=reset]):not([type=submit]):not([type=image]):not([type=radio]):focus,
.ff-form select:focus,
.ff-form textarea:focus {
  box-shadow:           0 0 8px 0 var(--primaryBlue60) !important;
  outline:              none !important;
}

/*
.ff-form textarea:required,
.ff-form input[type=text]:required,
.ff-form input[type=email]:required,
.ff-form input[type=password]:required,
.ff-form input[type=file]:required,
.ff-form input:not(.ff-multiselect):required {
  background-image:     radial-gradient(red 10%, transparent 25%);
  background-position:  top right;
  background-size:      1rem 1rem;
  box-shadow:           none;
}
*/

.ff-form select:enabled:invalid:not([multiple]):not([size]) {
  background-image:     url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%3Cpath%20fill%3D%22%23ef5350%22%20d%3D%22M7.41%2C8.58L12%2C13.17L16.59%2C8.58L18%2C10L12%2C16L6%2C10L7.41%2C8.58Z%22%20%2F%3E%0A%3C%2Fsvg%3E");
  box-shadow:           none;
}

.ff-form select:valid:not([multiple]):not([size]) {
  background-image:     url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M7.41%2C8.58L12%2C13.17L16.59%2C8.58L18%2C10L12%2C16L6%2C10L7.41%2C8.58Z%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.ff-form select:disabled:not([multiple]):not([size]) {
  background-image:     url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%3Cpath%20fill%3D%22%23696f74%22%20d%3D%22M7.41%2C8.58L12%2C13.17L16.59%2C8.58L18%2C10L12%2C16L6%2C10L7.41%2C8.58Z%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

.ff-form textarea:invalid,
.ff-form input[type=text]:invalid,
.ff-form input[type=email]:invalid,
.ff-form input[type=password]:invalid,
.ff-form input[type=file]:invalid,
.ff-form input:not(.ff-multiselect):invalid {
  /*  square -
      <svg width="16" height="16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" width="16" height="16" fill="#ef5350" stroke="black" stroke-width="0"/>
      </svg>

      exclamation -
      <svg style="width:24px;height:24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path fill="#000000" d="M11,4.5H13V15.5H11V4.5M13,17.5V19.5H11V17.5H13Z" />
      </svg>

     SVG src encoding: CGI::escape(foo).gsub('+', '%20'), decoding: CGI::unescape(foo)
     background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Crect%20x%3D%220%22%20y%3D%220%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%23ef5350%22%20stroke%3D%22black%22%20stroke-width%3D%220%22%2F%3E%0A%3C%2Fsvg%3E%0A");
     background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20style%3D%22width%3A24px%3Bheight%3A24px%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20fill%3D%22%23b71c1c%22%20d%3D%22M11%2C4.5H13V15.5H11V4.5M13%2C17.5V19.5H11V17.5H13Z%22%20%2F%3E%0A%20%20%20%20%20%20%3C%2Fsvg%3E%0A");
  */

  background-image:     radial-gradient(red 10%, transparent 25%);
  background-position:  top right;
  background-repeat:    no-repeat;
  background-size:      12px 12px;
  box-shadow:           none;
}

.ff-form textarea:valid,
.ff-form input[type=text]:valid,
.ff-form input[type=email]:valid,
.ff-form input[type=password]:valid,
.ff-form input[type=file]:valid {
  background-image:     none;
}

.ff-form input[type=checkbox],
.ff-form input[type=radio] {
  /* pad the element so it's nearly the same height as an input */
  margin:               6px auto 6px 0;
  vertical-align:       middle;

  /* styles for browser native radios/checkboxes  -
  margin:               7px auto 7px auto;
  width:                14px;
  */
}

input[type=checkbox]:hover:enabled,
input[type=radio]:hover:enabled {
  cursor:               pointer;
}

/* labels next to checkbox or radio */
.ff-form input[type=radio] + label,
.ff-form input[type=checkbox] + label {
  vertical-align:       middle;
}

/* spans inside labels (for radio/checkbox labels) */
.ff-form label > span {
  font-weight:          var(--regular);
  padding:              0 4px;
  vertical-align:       middle;
}

// span labels for disabled checkboxes and radios
.ff-form input[type=checkbox]:disabled + span,
.ff-form input[type=radio]:disabled + span {
  color:                var(--gray);
}

.ff-form div.icon-wrapper {
  position:             relative;
}

/* ----------------------------------------------------------------------------
 * File Input Buttons
 * ----------------------------------------------------------------------------
 */
::-webkit-file-upload-button,
::file-selector-button {
  background-color:     var(--primaryBlue) !important;
  border:               none !important;
  color:                white !important;
  height:               100% !important;
  margin-right:         .5em !important;
  padding:              2px 8px !important;
}

.ff-form input[type=file] {
  padding:              0 8px 0 0 !important;
}

/* ----------------------------------------------------------------------------
 * Custom Checkboxes
 * ----------------------------------------------------------------------------
 */

/* Material Design checkmark -
<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"  height="24" width="24" viewBox="0 0 24 24">
   <path fill="#333333" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
</svg>
*/

input[type=checkbox] {
  -webkit-appearance:   none;
  -moz-appearance:      none;
  appearance:           none;
  background-color:     white;
  background-position:  center;
  background-repeat:    no-repeat;
  background-size:      16px 16px;
  border:               1px solid var(--gray60);
  border-radius:        4px;
  width:                18px;
  height:               18px;
  min-width:            18px;
}

input[type=checkbox]:checked {
  background-image:     url("data:image/svg+xml;charset=UTF-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20version%3D%221.1%22%20%20height%3D%2224%22%20width%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%20%20%20%3Cpath%20fill%3D%22%23333333%22%20d%3D%22M21%2C7L9%2C19L3.5%2C13.5L4.91%2C12.09L9%2C16.17L19.59%2C5.59L21%2C7Z%22%20%2F%3E%0A%3C%2Fsvg%3E");
}

input[type=checkbox]:disabled,
input[type=checkbox].readonly {
  background-color:     var(--gray30);
  color:                var(--gray);
}

/* ----------------------------------------------------------------------------
 * Form Errors (see config.action_view.field_error_proc in application.rb)
 * ----------------------------------------------------------------------------
 */
div.field_with_errors,
span.field_with_errors input,
span.field_with_errors select,
span.field_with_errors textarea,
input.field_with_errors,
select.field_with_errors {
  border:               1px solid var(--red30) !important;
  background-color:     var(--red15) !important;
  background-image:     radial-gradient(red 10%, transparent 25%) !important;
  background-position:  top right !important;
  background-repeat:    no-repeat;
  background-size:      1rem 1rem !important;
  outline:              none;
}

input.alert {
  background-color:     var(--red15)    !important;
  color:                var(--red)      !important;
}

input.warning {
  background-color:     var(--yellow30) !important;
  color:                var(--dark)     !important;
}

/* ----------------------------------------------------------------------------
 * Filters
 * ----------------------------------------------------------------------------
 */

#filters_div label {
  font-size:            .9rem;
}
