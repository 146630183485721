@use "breakpoints";

div.employee-portal {
  display:                    grid;
  grid-gap:                   1.5em;
  grid-template-columns:      1fr;   /* mobile first */
  grid-template-areas:
    "info"
    "summary"
    "taxes"
}

div.employee-forms {
  display:                    grid;
  grid-gap:                   1.5em;
  grid-template-columns:      1fr;   /* mobile first */
  grid-template-areas:
    "w2s"
    "paychecks"
}

@media only screen and (min-width: breakpoints.$bp-md) {
  div.employee-portal {
    grid-template-columns:    1fr 1fr 1fr;
    grid-template-areas:
      "info summary summary"
      "taxes taxes taxes"
  }
  div.employee-forms {
    grid-template-columns:    auto auto;
    grid-template-areas:
    "paychecks w2s"
  }
}

div.employee-info {
  display:                    grid;
  grid-area:                  info;
  grid-gap:                   1em;
  grid-template-columns:      1fr;
}

div.employee-summary    { grid-area: summary; }
div.employee-taxes      { grid-area: taxes; }
div.employee-paychecks  { grid-area: paychecks; }
div.employee-w2s        { grid-area: w2s; }
