@use "breakpoints";

/* ----------------------------------------------------------------------------
 * Main Styles
 * ----------------------------------------------------------------------------
 */

:root {
  --dark:                     #333333;
  --dark60:                   #858585;
  --dark30:                   #c2c2c2;
  --dark15:                   #e1e1e1;

  --gray:                     #696f74;
  --gray60:                   #cccccc;
  --gray30:                   #ebebeb;
  --gray15:                   #f8f6f6;

  --slate:                    #08435a;  /* sidebar color */
  --slate60:                  #6b8f9c;
  --slate30:                  #b5c7ce;
  --slate15:                  #dbe3e7;

  --primaryBlue:              #1c92c0;
  --primaryBlue60:            #77beda;
  --primaryBlue30:            #bbdfec;
  --primaryBlue15:            #deeff6;

  --secondaryBlue:            #c8f2ff; /* header2 and login advert background */
  --secondaryBlue60:          #def8ff;
  --secondaryBlue30:          #effcff;
  --secondaryBlue15:          #f7feff;

  --red:                      #d51820;
  --red60:                    #e6757a;
  --red30:                    #f3babc;
  --red15:                    #f9ddde;

  --yellow:                   #fbe010;
  --yellow60:                 #fded70;
  --yellow30:                 #fef6b7;
  --yellow15:                 #fffbdc;

  /* ---------------------------------------------
   * Misc Vars
   * ---------------------------------------------
   */
  --primaryFont:              'Open Sans';
  --headerFont:               'Montserrat';

  /* font weights for open sans */
  --regular:                  400;
  --medium:                   600;
  --bold:                     700;

  /* ---------------------------------------------
   * Font
   * ---------------------------------------------
   */
  font-family:                var(--primaryFont), sans-serif;
  font-weight:                var(--regular);
  font-size:                  14px;
  line-height:                18px;  /* 19px */

  /* native radio and checkbox colors */
  accent-color:               var(--primaryBlue);
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align:             middle;
}

* {
  box-sizing:                 border-box; /* padding and border are included in the width and height */
}

button, input, select, textarea {
  font-family:                inherit;
  font-size:                  inherit;
  line-height:                inherit;
  margin:                     0;
}

html {
  background-color:           var(--dark);
  color:                      var(--dark);
}

body {
  height:                     100vh;
  margin:                     0;
}

a {
  color:                      var(--primaryBlue);
  text-decoration:            none;
}

a:hover {
  color:                      var(--gray);
}

b, strong {
  font-weight:                var(--bold);
}

fieldset {
  border:                     none;
  margin:                     0 0 1rem 0;
  padding:                    0;
}

label, legend, .label {
  display:                    inline-block;
  font-weight:                var(--medium);
}

label {
  word-break:                 keep-all;
  text-align:                 right;
}

label:hover {
  color:                      var(--dark60);
  cursor:                     pointer;
}

label.required::before {
  content:                    "*";
  color:                      var(--red);
  padding:                    0 .25em 0 0;
}

@media only screen and (max-width: breakpoints.$bp-sm) {
  label { text-align: left; }   /* labels move to left on small screens */
}

select:not([multiple]):not([size]) {
  appearance:                 none !important;
  -webkit-appearance:         none !important;
  -moz-appearance:            none !important;
}

summary {
  @extend .header;
  font-weight:                var(--medium);
  margin:                     0.5em 0;
}

summary:hover {
  background-color:           var(--yellow30);
  cursor:                     pointer;
}

ul, ol {
  margin:                     .25em 0 .25em 2em;
  padding:                    0;
}

ol > li {
  margin:                     .25rem 0;
}

ul.nobullets {
  list-style-type:            none;
  margin:                     .5rem 0;
}

ul.nobullets > li {
  padding:                    .25rem 0;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  color:                      var(--gray);
  font-family:                var(--headerFont), sans-serif;
  font-weight:                500;
  line-height:                normal;
}

.header {
  background-color:           var(--gray60);
  border-radius:              4px;
  color:                      var(--dark);
  margin-bottom:              .5rem;
  padding:                    .5rem;
}

.percent-sign::after {
  content:                    "%";
  vertical-align:             top;
}

.alert            { color:            var(--red);                                           }
.alert-light      { color:            var(--red60);                                         }
.placeholder      { color:            var(--gray60);                                        }
.link             { color:            var(--primaryBlue);                                   }
.h1               { font-size:        36px;                                                 }
.h2               { font-size:        30px;                                                 }
.h3               { font-size:        26px;                                                 }
.h4               { font-size:        22px;                                                 }
.h5               { font-size:        18px;                                                 }
.h6               { font-size:        14px;                                                 }
.bold             { font-weight:      var(--bold);                                          }
.medium           { font-weight:      var(--medium);                                        }
.regular          { font-weight:      var(--regular);                                       }
.italic           { font-style:       italic;                                               }
.show             { display:          block  !important;                                    }
.hide, .hidden    { display:          none   !important;                                    }
.center           { text-align:       center !important;                                    }
.right            { text-align:       right  !important;                                    }
.left             { text-align:       left   !important;                                    }
.noclick          { pointer-events:   none;                                                 }
.nowrap           { white-space:      nowrap;                                               }
.prewrap          { white-space:      pre;                                                  }
.truncate         { white-space:      nowrap; text-overflow: ellipsis; overflow: hidden;    }
.underline        { text-decoration:  underline;                                            }

.invisible {
  /* used for hidden elements that require accurate position/sizing info */
  visibility:                 hidden;
  position:                   absolute;
  top:                        -10000px;
  left:                       -10000px;
}

.monospace {
  font-family:                monospace, monospace;
  font-size:                  1rem;
  line-height:                1.3;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:                      var(--gray60) !important;
  opacity:                    1; /* Firefox */
}

::-ms-input-placeholder { /* Microsoft Edge */
  color:                      var(--gray60) !important;
}
