@use "misc";

/* ----------------------------------------------------------------------------
 * Flash/Status
 * ----------------------------------------------------------------------------
 */

div.flash-container {
  bottom:           1rem;
  display:          table; /* table forces the width to the size of the content */
  max-width:        50vw;
  position:         absolute;
  right:            1rem;
  z-index:          400;   /* set just below ff.GLOBALS.zIndex but above everything else */
}

div.flash-container-login {
  bottom:           none;
  top:              .5rem;
}

div.flash-close {
  cursor:           pointer;
  color:            black;
  font-size:        1.75rem;
  position:         absolute;
  top:              1px;
  right:            4px;
}

div.flash-close:hover {
  color:            rgba(0,0,0,.5);
}

.flash-info,
.flash-warning {
  background-color: var(--yellow60) !important;
  color:            black !important;
}

.flash-alert,
.flash-error {
  background-color: var(--red) !important;
  color:            white !important;
}

.flash {
  @extend           .ff-info;
  font-weight:      var(--medium);
  padding:          1.25rem 1.75rem 1.25rem 1.5rem;
}
