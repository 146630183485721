/* ----------------------------------------------------------------------------
 * Sortable Styles
 * ----------------------------------------------------------------------------
 */

i.sortable-handle {
  font-size:              1.25rem;
}

i.sortable-handle:hover {
  cursor:                 grab !important;
}

.sortable-ghost {
  opacity:                .5;
  background-color:       var(--yellow60) !important;
}

.sortable-selected {
  background-color:       var(--yellow60) !important;
}

/*
i.sortable-handle:before {
  font-family:            'Font Awesome 6 Pro';
  font-style:             normal;
  font-weight:            400;
  content:                '\f58e';
}

i.sortable-handle:hover:before {
  font-family:            'Font Awesome 6 Pro';
  font-style:             normal;
  font-weight:            900;
  cursor:                 grab !important;
}
*/
