/* ----------------------------------------------------------------------------
 * Tooltip Styles
 * ----------------------------------------------------------------------------
 */

.ff-tooltip {
  color:              var(--primaryBlue);
}

.ff-tooltip:hover:not(.disabled) {
  filter:             brightness(75%);
}

.ff-tooltip-content {
  display:            table; /* table forces the width to the size of the content */
  max-width:          25vw;
  margin-right:       .5rem;
  padding:            .5rem;
  position:           absolute;
}

.ff-bubble::after {
  font-family:        'Font Awesome 6 Pro';
  font-size:          .75rem;
  font-weight:        900;
  float:              right;
  color:              var(--primaryBlue);
  content:            '\e1e3';
  margin:             0 .25rem 0 1rem;
  position:           relative;
  top:                -0.5rem;
}

.ff-bubble:hover::after {
  filter:             brightness(75%);
}
