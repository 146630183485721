@use "breakpoints";

/* ----------------------------------------------------------------------------
 * Table Containers/Wrappers
 * ----------------------------------------------------------------------------
 */
div.scrollable { overflow-y: auto; }  /* see initScrollables in ff_functions.js */
div.stretchy   {}                     /* these divs will dynamically resize their height to reach the footer */

/* no scrollables or stretching on small screens */
@media only screen and (max-width: breakpoints.$bp-md) {
  div.scrollable {
    margin-bottom:  1rem;
    overflow-y:     none;
  }
  div.stretchy {
    height:         100%;
    margin-bottom:  1rem;
  }
}

div.scrollable-table {
  border:               1px solid var(--gray60);
  border-top:           none;
}

div.scrollable-table table thead {
  /* sticky thead */
  position:             sticky;
  top:                  0;
}

div.scrollable-table table thead:after {
  /* sticky bottom border for thead */
  content:              '';
  position:             absolute;
  width:                100%;
  bottom:               0;
  border-bottom:        1px solid black;
}


div.scrollable-table table tfoot {
  /* sticky tfoot */
  position:             sticky;
  bottom:               0;
}

div.scrollable-table table tfoot:before {
  /* sticky top border for tfoot */
  content:              '';
  position:             absolute;
  width:                100%;
  top:                  0;
  border-top:           1px solid black;
}

div.scrollx {
  width:                100%;
  overflow-x:           auto;
}

/* ----------------------------------------------------------------------------
 * HTML Tables
 * ----------------------------------------------------------------------------
 */
table.ff-table {
  border-collapse:      collapse;
  background-color:     var(--gray30);
  width:                100%;
}

table.ff-table td, table.ff-table th {
  padding:              .5rem .5rem;
}


table.ff-table-striped td {
  border-top:           1px solid var(--gray60);
}

table.ff-table th {
  text-align:           left;
  white-space:          nowrap;
}

table.ff-table thead th,
table.ff-table tfoot th,
table.ff-table tfoot td {
  background-color:     var(--gray);
  color:                white;
  font-weight:          var(--medium);
  z-index:              1;
}

table.ff-table thead th a {
  color:                white;
}

table.ff-table thead th a:hover {
  color:                var(--dark30);
}

table.ff-table-bordered {
  border:               1px solid var(--gray60);
}

table.ff-table-striped tbody tr:nth-of-type(even) {
  background-color:     white;
}

table.ff-table tbody tr[data-url]:not([data-url=""]):hover,
table.ff-table tbody tr[data-checkbox]:not([data-checkbox=""]):hover {
  background-color:     var(--yellow30) !important;
  color:                black;
  cursor:               pointer;
}

table.ff-table td.currency {
  text-align:           right;
  max-width:            15rem;
  width:                10rem;
  white-space:          nowrap;
  padding-right:        1rem !important;
}

table.ff-table th.currency {
  text-align:           right;
  padding-right:        .75rem !important;
  white-space:          nowrap;
}

table.ff-table th.thead,
table.ff-table td.thead {
  /* mimic the thead style for th,td items in the tbody */
  background-color:     var(--gray);
  color:                white;
  font-weight:          var(--medium);
}

table.ff-table th.tfoot,
table.ff-table td.tfoot {
  /* mimic the tfoot style for th,td items in the tbody */
  background-color:     var(--gray60);
  font-weight:          var(--medium);
}

table.ff-table th.thead a {
  color:                var(--gray15);
}

table.ff-table th.thead a:hover {
  color:                var(--yellow60);
}

table.ff-table tr.disabled {
  background-color:     var(--gray60) !important;
  border-top:           1px solid var(--gray);
  border-bottom:        1px solid var(--gray);
}

table.ff-table tr.highlight {
  background-color:     var(--yellow15) !important;
  border-top:           1px solid var(--gray60);
  border-bottom:        1px solid var(--gray60);
}

table.ff-table tr.error {
  background-color:     var(--red15) !important;
  color:                var(--red);
}

table.ff-table tr.warning,
table.ff-table tr.info {
  background-color:     var(--yellow30) !important;
}

table.ff-table .sorted {
  color:                var(--yellow60);
}

table.ff-table th input[type=checkbox],
table.ff-table td input[type=checkbox] {
  margin:               0 auto !important;
}

/* ----------------------------------------------------------------------------
 * Table Icons
 * ----------------------------------------------------------------------------
 */

table.ff-table td.icons {
  text-align:           center;
  white-space:          nowrap;
  padding-right:        1em;
}

table.ff-table i:hover {
  filter:               brightness(90%);
}

table.ff-table a.sort:hover {
  color:                var(--yellow60);
}

/* ----------------------------------------------------------------------------
 * Inline Editing Table Styles
 * ----------------------------------------------------------------------------
 */

table.ff-table-compact td,
table.ff-table-compact th {
  padding:              .25rem .25rem;
}

table.ff-table-compact input[type=text],
table.ff-table-compact select {
  /* height:               26px !important;  */
  height:               1.75em !important;
  padding:              2px 8px !important;
}

table.ff-table-small td {
  font-size:            0.8rem;
}

table.ff-table-small td,
table.ff-table-small th {
  padding:              .15rem .15rem;
}

table.ff-table-small input,
table.ff-table-small select {
  padding:              2px 4px !important;
}

/* ----------------------------------------------------------------------------
 * Misc Table Styles
 * ----------------------------------------------------------------------------
 */
th.currency + th:not([class='currency']),
td.currency + td:not([class='currency']) {
  /* add space between a currency/right column on left and regular column on its right */
  padding-left:         1rem;
}

th.one-pct {
  width:                1%; /* allow TH to expand to the size of its TDs */
}

/* ----------------------------------------------------------------------------
 * Grid Tables - Separate thead and tbody divs with matching grid column sizes
 * ----------------------------------------------------------------------------
 */

/*
div.thead {
  background-color:       var(--gray);
  color:                  white;
  font-weight:            var(--medium);
  width:                  100%;
}

div.tbody {
  background-color:       var(--gray30);
  border:                 1px solid var(--gray60);
  overflow-x:             hidden;
}

div.tbody >div.tr {
  border-top:             1px solid var(--gray60);
}

div.striped >div.tr:nth-of-type(even) {
  background-color:       white;
}

div.tr {
  display:                grid;
  grid-gap:               0;
}

div.tr >div {
  padding:                .4rem 1rem;
  white-space:            nowrap;
}

div.truncate {
  overflow:               hidden;
  text-overflow:          ellipsis;
  white-space:            nowrap;
}
*/

/* ----------------------------------------------------------------------------
 * CSS Tables
 * ----------------------------------------------------------------------------
 */
/*
div.table {
  display:              table;
  width:                100%;
}

div.tr {
  display:              table-row;
}

div.td,
div.th {
  border-bottom:        1px solid var(--gray400);
  display:              table-cell;
  padding:              .25em .25em;
}

div.thead {
  display:              table-header-group;
}

div.tbody {
  display:              table-row-group;
}

div.thead div.th {
  background-color:     var(--gray200);
  color:                var(--gray800);
  position:             sticky;
  top:                  0;

  border-bottom:        none !important;
  box-shadow:           inset 0 -1px 1px -1px black;
}

div.striped div.tbody div.tr:nth-of-type(even) {
  background-color:     var(--gray50);
}

div.table i:hover {
  filter:               opacity(.8);
  position:             relative;
  top:                  -1px;
  right:                -1px;
}

div.table div.tr:last-child div.td {
  border-bottom:        none;
}

*/
