@use "breakpoints";

/* ----------------------------------------------------------------------------
 * Individual View Styles
 * ----------------------------------------------------------------------------
 */

.ga-a1 { grid-area: a1 } .ga-a2 { grid-area: a2 } .ga-a3 { grid-area: a3 } .ga-a4 { grid-area: a4 }
.ga-b1 { grid-area: b1 } .ga-b2 { grid-area: b2 } .ga-b3 { grid-area: b3 } .ga-b4 { grid-area: b4 }
.ga-c1 { grid-area: c1 } .ga-c2 { grid-area: c2 } .ga-c3 { grid-area: c3 } .ga-c4 { grid-area: c4 }
.ga-d1 { grid-area: d1 } .ga-d2 { grid-area: d2 } .ga-d3 { grid-area: d3 } .ga-d4 { grid-area: d4 }
/* we cannot use grid-area named e1, e2, etc. because the webpack compiler converts it to e-notation */
.ga-f1 { grid-area: f1 } .ga-f2 { grid-area: f2 } .ga-f3 { grid-area: f3 } .ga-f4 { grid-area: f4 }
.ga-g1 { grid-area: g1 } .ga-g2 { grid-area: g2 } .ga-g3 { grid-area: g3 } .ga-g4 { grid-area: g4 }

/* ------------------------------------------------
 * Client Bills
 * ------------------------------------------------
 */
div.client-bills {
  grid-template-rows:       repeat(12, auto);
  grid-template-columns:    auto 1fr;
  grid-template-areas:
    "a1 a2"  "b1 b2"  "c1 c2"  "d1 d2"  "f1 f2"  "g1 g2"  "a3 a4"  "b3 b4"  "c3 c4"  "d3 d4"  "f3 f4"  "g3 g4";
}

@media only screen and (min-width: breakpoints.$bp-lg) {
   div.client-bills {
    grid-template-rows:       repeat(6, auto);
    grid-template-columns:    auto 1fr auto 1fr;
    grid-template-areas:
      "a1 a2 a3 a4"  "b1 b2 b3 b4"  "c1 c2 c3 c4"  "d1 d2 d3 d4"  "f1 f2 f3 f4"  "g1 g2 g3 g4";
   }
}

/* ------------------------------------------------
 * Purchase Orders
 * ------------------------------------------------
 */
div.purchase-orders {
  grid-template-rows:       repeat(10, auto);
  grid-template-columns:    auto 1fr;
  grid-template-areas:
    "a1 a2"  "b1 b2"  "c1 c2"  "d1 d2"  "f1 f2"  "a3 a4"  "b3 b4"  "c3 c4"  "d3 d4"  "f3 f4";
}

@media only screen and (min-width: breakpoints.$bp-lg) {
   div.purchase-orders {
    grid-template-rows:       repeat(5, auto);
    grid-template-columns:    auto 1fr auto 1fr;
    grid-template-areas:
      "a1 a2 a3 a4"  "b1 b2 b3 b4"  "c1 c2 c3 c4"  "d1 d2 d3 d4"  "f1 f2 f3 f4";
   }
}

/* ------------------------------------------------
 * Accounting Transactions
 * ------------------------------------------------
 */
div.acct-txns {
  grid-template-rows:       repeat(10, auto);
  grid-template-columns:    auto 1fr;
  grid-template-areas:
    "a1 a2"  "b1 b2"  "c1 c2"  "b3 b4"  "c3 c4"  "d1 d2"  "a3 a4"  "d3 d4"  "f1 f2"  "f3 f4";
}

@media only screen and (min-width: breakpoints.$bp-xl) {
   div.acct-txns {
    grid-template-rows:       repeat(5, auto);
    grid-template-columns:    auto 1fr auto 1fr;
    grid-template-areas:
      "a1 a2 a3 a4"  "b1 b2 b3 b4"  "c1 c2 c3 c4"  "d1 d2 d3 d4"  "f1 f2 f3 f4";
   }
}

/* ------------------------------------------------
 * Fund Raising Transactions
 * ------------------------------------------------
 */
div.fr-txns {
  grid-template-rows:       repeat(10, auto);
  grid-template-columns:    auto 1fr;
  grid-template-areas:
    "a1 a2"  "b1 b2"  "c1 c2"  "b3 b4"  "c3 c4"  "d1 d2"  "a3 a4"  "d3 d4"  "f1 f2"  "f3 f4";
}

@media only screen and (min-width: breakpoints.$bp-xl) {
   div.fr-txns {
    grid-template-rows:       repeat(5, auto);
    grid-template-columns:    auto 1fr auto 1fr;
    grid-template-areas:
      "a1 a2 a3 a4"  "b1 b2 b3 b4"  "c1 c2 c3 c4"  "d1 d2 d3 d4"  "f1 f2 f3 f4";
   }
}
