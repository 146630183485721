/* animation filter, ex. 'animation: fadeIn 0.3s' (see modal.css) */
@keyframes fadeIn {
  from        { opacity: 0; }
  to          { opacity: 1; }
}

@keyframes fadeOut {
  from        { opacity: 1; }
  to          { opacity: 0; }
}

@keyframes highlightError {
  0%         { background-color: var(--red);        }
  20%        { background-color: var(--red60);      }
  40%        { background-color: var(--red30);      }
  60%        { background-color: var(--red15);      }
  80%        { background-color: var(--gray15);     }
  100%       { background-color: none;              }
}

@keyframes highlightInfo {
  0%        { background-color: var(--yellow);      }
  20%       { background-color: var(--yellow60);    }
  40%       { background-color: var(--yellow30);    }
  60%       { background-color: var(--yellow15);    }
  80%       { background-color: var(--gray15);      }
  100%      { background-color: none;               }
}

.overlay {
  background-color:     rgba(0,0,0,0.2);
  cursor:               wait;
  width:                100%;
  height:               100%;
  overflow-y:           hidden;
  position:             fixed;
  z-index:              10000;
}

.spinner {
  /*animation: spinner 2s linear infinite;*/
  animation:            spinner 2s ease-in-out infinite;
}
@keyframes spinner {
  from      { transform: rotate(0deg);   }
  to        { transform: rotate(359deg); }
}
/*
@keyframes spin { 100% { transform:rotate(360deg); } }
*/
