/* ----------------------------------------------------------------------------
 * Breakpoint Definitions - access from JS using the following code -
 *   getComputedStyle(document.documentElement).getPropertyValue('--current-breakpoint');
 * ----------------------------------------------------------------------------
 */

/* Breakpoints - defined with SCSS variables in order to use within media queries */
$bp-sm: 420px;
$bp-md: 768px;
$bp-lg: 1024px;
$bp-xl: 1280px;
$bp-ws: 2048px;

/* mobile first */
:root { --current-breakpoint: xs; }
@media only screen and (min-width: $bp-sm) { :root { --current-breakpoint: sm; } }
@media only screen and (min-width: $bp-md) { :root { --current-breakpoint: md; } }
@media only screen and (min-width: $bp-lg) { :root { --current-breakpoint: lg; } }
@media only screen and (min-width: $bp-xl) { :root { --current-breakpoint: xl; } }
@media only screen and (min-width: $bp-ws) { :root { --current-breakpoint: ws; } }

