@use "breakpoints";

/* ----------------------------------------------------------------------------
 * Grid Class Helpers
 * ----------------------------------------------------------------------------
 */
.grid {
  display:                grid;
  align-items:            center;
  grid-column-gap:        1rem;
  grid-row-gap:           .5rem;
}

.grid-auto-200 {
  grid-template-columns:  repeat(auto-fit, minmax(200px, 1fr));
  align-items:            start;
}

.grid-auto-350 {
  /* auto-fit grid to fit a date range widget */
  grid-template-columns:  repeat(auto-fit, minmax(350px, 1fr));
  align-items:            start;
}

/* column helpers */
.col-all {
  grid-column-start:      1;    /* span all cols */
  grid-column-end:        -1;
}

.row-all {
  grid-row-start:         1;    /* span all rows */
  grid-row-end:           -1;
}

.align-start              { align-items: start      }
.flow-column              { grid-auto-flow: column  } /* default is by row */
.grid-center              { justify-self: center    }
.grid-start               { justify-self: start     }
.grid-end                 { justify-self: end       }
.col-2                    { grid-column:  span 2    }
.col-3                    { grid-column:  span 3    }
.col-4                    { grid-column:  span 4    }
.col-5                    { grid-column:  span 5    }
.col-6                    { grid-column:  span 6    }
.row-2                    { grid-row:     span 2    }
.row-3                    { grid-row:     span 3    }
.row-4                    { grid-row:     span 4    }
.sub-grid                 { display:      contents  } /* allow a container's elements to be affected by parent grid


/* media query grid classes */

.grid-2-1     { grid-template-columns: repeat(4, auto);       }

@media only screen and (max-width: breakpoints.$bp-md) {
  .grid-2-2   { grid-template-columns: auto auto !important;  }   /* force 2 cols */
  .grid-2-1   { grid-template-columns: auto auto !important;  }   /* force 2 cols */
  .grid-1-1   { grid-template-columns: auto !important;       }   /* force 1 cols */

  .grid-m-h   { display: none; }
}

@media only screen and (max-width: breakpoints.$bp-sm) {
  .grid-2-2   { grid-template-columns: auto auto !important;  }   /* force 2 cols */
  .grid-2-1   { grid-template-columns: auto !important;       }   /* force 1 col */
  .grid-1-1   { grid-template-columns: auto !important;       }   /* force 1 col */

  .grid-m-h   { display: none; }
  .grid-s-h   { display: none; }
}
