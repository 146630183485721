/* ----------------------------------------------------------------------------
 * FastFundMultiSelect Styles
 * ----------------------------------------------------------------------------
 */

select.ff-multiselect {
  display:                none;
}

select.ff-multiselect-label {
  color:                  var(--gray);
}

div.ff-multiselect-header {
  background-color:       var(--gray30);
  border-bottom:          1px solid var(--gray60);
  display:                grid;
  padding:                .5rem;
}

div.ff-multiselect-filter {
  display:                grid;
  align-items:            center;
  grid-column-gap:        .5rem;
  grid-template-columns:  auto 1fr auto;
  margin-top:             .5rem;
}

div.ff-multiselect-links {
  display:                grid;
  grid-auto-flow:         column;
  grid-column-gap:        1rem;
  white-space:            nowrap;
}

div.ff-multiselect-header i {
  margin-right:           .5rem;
}

div.ff-multiselect-header i.close {
  color:                  var(--dark);
  margin:                 0 0 0 1rem;
}

div.ff-multiselect-header i.trash {
  color:                  var(--dark);
}

div.ff-multiselect-header a:focus {
  outline:                none;
}
