@use "breakpoints";
@use "fastfund";

/* ----------------------------------------------------------------------------
 * Dashboard Styles
 * ----------------------------------------------------------------------------
 */

 $container-height:       275px; /* fixed height to ensure the graph and summary divs line up */
 $canvas-max-height:      225px;

.dashboard-container {
  align-items:            start !important;
  grid-column-gap:        10px !important;
  grid-row-gap:           10px !important;
  grid-template-columns:  repeat(4, 1fr);
}

.dashboard-summary-container {
  @extend .dashboard-container;
  grid-template-columns:  1fr 1fr !important;
  height:                 $container-height;
}

.dashboard-container .scrollable-table {
  border:                 none !important;
  max-height:             250px;
  overflow-y:             auto;
}

.dashboard-container canvas {
  max-height:             $canvas-max-height;
  width:                  100%;
}

.dashboard-chart {
  padding:                5px 0;
}

.dashboard-graph-container {
  height:                 $container-height;
}

.dashboard-graph {
  padding:                0;
}

.dashboard-summary {
  align-content:          space-evenly;
  height:                 75%;
  padding:                0 5px;
}

.dashboard-header {
  border-bottom:          1px solid var(--gray60);
  background-color:       var(--gray30);
  padding:                .5rem;
  grid-column-gap:        .25rem !important;
}

.dashboard-title {
  @extend .h6;
  color:                  var(--dark);
  white-space:            nowrap;
}

.dashboard-item {
  border:                 1px solid var(--gray60);
}

.dashboard-small  { height:      100%;   }
.dashboard-medium { grid-column: span 2; }
.dashboard-large  { grid-column: 1/-1;   }

@media only screen and (max-width: breakpoints.$bp-xl) {
  //.dashboard-container   { border: 1px solid blue; }
  /* dashboard is a fixed 2 columns at 1280 and below */
  .dashboard-container   { grid-template-columns: 1fr 1fr; }

  /* medium items span all columns */
  .dashboard-medium      { grid-column: 1/-1; }

  /* decrease canvas size */
  .dashboard-container canvas { max-height: $canvas-max-height; }
}

@media only screen and (max-width: breakpoints.$bp-sm) {
  //.dashboard-container   { border: 1px solid yellow; }
  /* dashboard is a fixed 1 column at 640 and below */
  .dashboard-container   { grid-template-columns: 1fr; }

  /* decrease canvas size */
  .dashboard-container canvas { max-height: $canvas-max-height; }
}
