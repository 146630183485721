/* ----------------------------------------------------------------------------
 * Buttons
 * ----------------------------------------------------------------------------
 */

input[type=button],
button::-moz-focus-inner {
  border:               0;
  padding:              0;
}

.ff-button {
  background-color:     var(--primaryBlue);
  border:               2px solid var(--primaryBlue);
  border-radius:        4px !important;
  color:                white;
  display:              inline-block;
  font-weight:          var(--regular);
  padding:              4px 8px;
  text-transform:       uppercase;
  transition:           all .1s ease-in-out;
  transition-property:  background-color, border, color;
  white-space:          nowrap;
}

.ff-button-flat {
  border-radius:        0 !important;
}

.ff-button:disabled {
  background-color:     var(--gray60);
  border:               2px solid var(--gray60);
}

.ff-button:enabled {
  cursor:               pointer;
}

.ff-button:enabled:hover,
.ff-button:enabled:focus {
  background-color:     white;
  border:               2px solid var(--primaryBlue);
  color:                var(--primaryBlue);
  outline:              none;
}

.ff-button:enabled:active {
  background-color:     var(--slate);
  border:               2px solid var(--slate);
  color:                var(--yellow);
}

.ff-button-alert {
  background-color:     var(--red);
  border:               2px solid var(--red);
}

.ff-button-alert:enabled:hover,
.ff-button-alert:enabled:focus {
  background-color:     var(--yellow60);
  border:               2px solid var(--red);
  color:                var(--dark);
  outline:              none;
}

.ff-button-row {
  display:              grid;
  grid-template:        1/auto;   /* 1 row, xButtons (columns) */
  grid-column-gap:      .5rem;
}

.ff-button-row > .ff-button {
  grid-row:             1;        /* all buttons on a single row */
}

/* ----------------------------------------------------------------------------
 * Buttons
 * ----------------------------------------------------------------------------
 */

 /*
.ff-button2 {
  background-color:   var(--primaryBlue);
  border:             none;
  border-radius:      4px !important;
  color:              white;
  display:            inline-block;
  font-weight:        var(--medium);
  margin:             0;
  padding:            9px 12px;
  text-transform:     uppercase;
  transition:         filter .15s ease-in-out;
}

.ff-button2:disabled {
  background-color:   var(--gray30);
  color:              var(--gray60);
}

.ff-button2:enabled:hover {
  cursor:             pointer;
  filter:             brightness(80%);
}

.ff-button2:enabled:focus,
.ff-button2:enabled:active {
  filter:             brightness(110%);
}

.ff-button2-secondary {
  background-color:   white;
  border:             1px solid var(--dark60);
  color:              var(--dark);
}

.ff-button2-alert {
  background-color:   var(--red);
  color:              white;
}

.ff-button2-warning {
  background-color:   var(--yellow);
  color:              var(--dark);
}

*/

