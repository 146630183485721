@use "breakpoints";
@use "grids";

/* ----------------------------------------------------------------------------
 * Reports Styles
 * ----------------------------------------------------------------------------
 */

div.report-index {
  @extend .grid;

  align-items:            start;
  grid-row-gap:           1.5rem;
  grid-template-columns:  1fr 1fr;
}

ul.report-menu {
  list-style-type:        none;
  margin:                 .5rem 0 0 1rem;
}

ul.report-menu > li {
  margin:                 .25rem 0;
}

ul.report-menu > li > i {
  margin:                 0 .25rem 0 0;
}

/* ----------------------------------------------------------------------------
 * 2-column x 2-column responsive classes for report fields
 *
 *        |       |col1label|col1field|   |col2label|col2field|       |
 *
 * ----------------------------------------------------------------------------
 */
div.report-div {
  @extend .grid;

  grid-template-columns:  auto 1fr;
  align-items:            start;
  justify-items:          start;
}

div.report-div-col {
  @extend .grid;
  /* default to "8rem auto" to ensure labels align when content is resized to single column */
  grid-template-columns:  minmax(8rem, auto) auto;
}

div.report-div-col-lg {
  @extend .report-div-col;
  grid-template-columns:  minmax(12rem, auto) auto;
}

div.report-div-col-auto {
  @extend .report-div-col;
  grid-template-columns:  auto auto;
}

@media only screen and (max-width: breakpoints.$bp-lg) {
  /* force to a single column at the lg breakpoint */
  div.report-div          { grid-template-columns: auto }
}

/* ----------------------------------------------------------------------------
 * Report Filters
 * ----------------------------------------------------------------------------
 */

div.filter-root input[type=text],
div.filter-root select {
  /* filter form elements are reduced to conserve space */
  height:                 1.75em !important;
  padding:                2px 8px !important;
}

div.filter-header {
  @extend                 .grid, .flow-column;

  background:             var(--gray30);
  border-bottom:          1px solid var(--gray60);
  padding:                .25rem;
  margin:                 -.5rem -.5rem .5rem -.5rem;
}

div.filter-header-links {
  @extend                 .grid, .grid-start, .flow-column;
}

div.filter-header-selects {
  @extend                 .grid, .grid-end, .flow-column;
}

div.filter-branch {
  border:                 1px solid var(--gray60);
  margin:                 .5rem 0;
  padding:                .5rem;
}

div.filter-row {
  @extend                 .grid;

  grid-template-columns:  auto 1fr auto;
  background:             var(--gray15);
  border:                 1px solid var(--gray30);
  padding:                .25rem;
  margin:                 .25rem 0;
}

/* ----------------------------------------------------------------------------
 * Report Sets
 * ----------------------------------------------------------------------------
 */

form.report-set {
  border:                 1px solid var(--gray60);
  background-color:       var(--gray15);
  margin-top:             1em;
}

div.report-set-header {
  @extend                 .grid, .grid-start;
  grid-auto-flow:         column;
  background-color:       var(--gray30);
  border-bottom:          1px solid var(--gray60);
  padding:                .25em .5em;
}

div.report-set-header-left {
  display:                flex;
  align-items:            center;
  flex-wrap:              wrap;
  gap:                    .5em;
}

div.report-set-header-right {
  @extend                 .grid;
  grid-auto-flow:         column;
  justify-self:           end;
}

div.report-set-root-container {
  overflow-y:             auto;
  overflow-x:             hidden;
  padding:                1em 1em;
}

div.report-set-group,
div.report-set-item {
  @extend                 .grid;
  grid-template-columns:  auto auto auto 1fr;
  grid-column-gap:        .25em;
  justify-items:          start;
  padding:                2px 0;
}

span.report-set-group {
  font-weight:            var(--medium);
}

div.report-set-item-nested {
  margin-left:            42px;
}

span.report-set-label:hover {
  background-color:       var(--yellow30) !important;
  cursor:                 pointer;
}
