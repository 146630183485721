@use "breakpoints";
@use "fastfund";
@use "grids";
@use "headers";

/* ----------------------------------------------------------------------------
 * Transaction View Styles
 * ----------------------------------------------------------------------------
 */

.donor-header {
  @extend                   .content-header-extra, .grid;
  grid-template-areas:      "name info";
  margin:                   -0.5rem 0 1rem 0 !important;
  padding:                  .5rem .75rem !important;
}

.donor-header input[type=checkbox] {
  margin:                   0;
}

.donor-info {
  @extend                   .grid, .grid-end, .flow-column;
  grid-area:                info;
}

.donor-name {
  @extend                   .medium;
  grid-area:                name;
}

@media only screen and (max-width: breakpoints.$bp-xl) {
  .donor-header { grid-template-areas: "name name" "info info" }
  .donor-info   { justify-self: start }
}
