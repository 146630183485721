/* ----------------------------------------------------------------------------
 * Mask Input Styles
 * ----------------------------------------------------------------------------
 */
.ff-mask-container {
  display:                  flex;
  flex-flow:                row nowrap;
  align-items:              center;
}

.ff-mask-separator {
  font-size:                1.1rem;
  justify-self:             center;
}

.ff-mask-icon {
  color:                    var(--gray) !important;
  font-size:                16px;
  position:                 absolute;
  margin:                   0 !important;
  top:                      50%;
  transform:                translateY(-50%);
}

.ff-mask-icon:hover:not(.disabled) {
  color:                    var(--gray60) !important;
}

input.ff-mask::-ms-clear {
  display:                  none;
}

