@use "breakpoints";

/* ----------------------------------------------------------------------------
 * CSS for the _sidemenu.erb partial
 * ----------------------------------------------------------------------------
 */

.burger-menu {
  display:                block;
  height:                 100vh;
  position:               absolute;
  z-index:                2;
}

.sidemenu-title {
  color:                  var(--secondaryBlue);
  font-size:              2.25rem;
  font-weight:            var(--bold);
  margin:                 3rem 1rem 2rem 1rem;
  text-align:             center;
}

.sidemenu-item-header {
  color:                  var(--secondaryBlue);
  font-weight:            var(--medium);
  font-size:              1.15rem;
  margin:                 1.5rem 0 .5rem 0;
  padding:                0 .75rem;
  text-transform:         uppercase;
}

.sidemenu-item-link, {
  color:                  white;
  display:                block;
  font-weight:            var(--medium);
  padding:                .15rem .5rem .15rem 1rem;
}

.sidemenu-item-selected {
  color:                  var(--yellow);
}

.sidemenu-item-underline {
  border-bottom:          1px solid var(--secondaryBlue);
}

.sidemenu-path {
  margin-bottom:          2rem;
}

.sidemenu-select {
  display:                grid;
  grid-template-columns:  1fr auto;
  font-weight:            var(--medium);
  padding:                .25rem .25rem .25rem 1rem;
}

.sidemenu-select:hover:not(.sidemenu-selected) {
  color:                  var(--yellow);
}

.sidemenu-item-link:hover {
  color:                  var(--yellow);
}

.sidemenu-menu {
  background-color:       var(--slate) !important;
  border:                 1px solid var(--slate) !important;
  color:                  white !important;
}

@media only screen and (min-width: breakpoints.$bp-lg) {
  .sidemenu-title {
    margin:               1.25rem 1rem 2rem 1rem;
  }
 }
