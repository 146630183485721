/* ----------------------------------------------------------------------------
 * FastFundSelector Styles
 * ----------------------------------------------------------------------------
 */

div.ff-selector {
  display:                grid;
  grid-template-columns:  1fr auto 1fr;
  margin:                 1rem 0 0 0;
}

div.ff-selector select {
  display:                none;
}

div.ff-selector label {
  justify-self:           center;
}

div.ff-selector-icons {
  display:                grid;
  align-self:             center;
  align-items:            center;
  grid-template-columns:  auto;
  justify-items:          center;
  padding:                0;
  margin:                 0;
}

div.ff-selector-icons i {
  font-size:              1.5rem;
  margin:                 .25rem 0;
  padding:                .75rem 1rem;
}

div.ff-selector-icons i:hover {
  color:                  var(--dark60);
}

ul.ff-selector {
  background-color:       white;
  border:                 1px solid var(--gray60);
  color:                  var(--dark);
  /* prevent selection highlighting inside the lists */
  -moz-user-select:       -moz-none;
  -khtml-user-select:     none;
  -webkit-user-select:    none;
}

